import React, { useState, useEffect } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { US } from "country-flag-icons/react/3x2";
import { EU } from "country-flag-icons/react/3x2";
import { PL } from "country-flag-icons/react/3x2";
import { UA } from "country-flag-icons/react/3x2";
const CURRENCY_CODE = {
  USD: "USD",
  EUR: "EUR",
  UAH: "UAH",
};
function MyCurrencies() {
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);
  const [rateId, setRateId] = useState(1);
  const [usdToUah, setUsdToUah] = useState(41.01);
  const [eurToUah, setEurToUah] = useState(45.01);
  const [plnToUah, setPlnToUah] = useState(10.01);
  const [uahToUah, setUahToUah] = useState(1);

  useEffect(() => {
    axios
      .get("https://server.neoceram.in.ua/api/v1/currencies/rate")
      .then((response) => {
        setRateId(response.data[0].id),
          setUsdToUah(response.data[0].usd),
          setEurToUah(response.data[0].eur),
          setPlnToUah(response.data[0].pln),
          setUahToUah(response.data[0].uah);
      });
  }, []);

  const handleClick = async (e) => {
    const target = e.currentTarget;
    target.classList.add("_sendingFile");
    setAlertSuccessOpen(false);
    setAlertErrorOpen(false);
    try {
      const currunciesRateData = {
        id: rateId,
        usd: usdToUah,
        eur: eurToUah,
        pln: plnToUah,
        uah: uahToUah,
      };
      await axios
        .put(`http://localhost:3003/api/v1/currencies/rate`, currunciesRateData)
        .then((res) => {
          console.log("upgread");
          setAlertSuccessOpen(true);
          target.classList.remove("_sendingFile");
        });
    } catch (error) {
      console.log(error);
      setAlertErrorOpen(true);
      target.classList.remove("_sendingFile");
    }
  };

  return (
    <div className="myCurrencies-wrapper">
      <div className="myCurrencies-wrapper2">
        <h3 className="currency-title">Мій курс валют</h3>
        <div className="myCurrencies-containers-wrapper">
          <div className="myCurrencies-showRates-container">
            <div className="currency-currency-container">
              <US title="United States" className="currency-showRates-flag" />
              <h3>USD</h3>
            </div>
            <div className="myCurrencies-input-wrapper">
              <input
                className="myCurrencies-input"
                type="number"
                value={usdToUah}
                placeholder="100₴"
                onChange={(e) => setUsdToUah(e.target.value)}
              />
              ₴
            </div>
          </div>
          <div className="myCurrencies-showRates-container">
            <div className="currency-currency-container">
              <EU title="United States" className="currency-showRates-flag" />
              <h3>EUR</h3>
            </div>
            <div className="myCurrencies-input-wrapper">
              <input
                className="myCurrencies-input"
                type="number"
                value={eurToUah}
                placeholder="100₴"
                onChange={(e) => setEurToUah(e.target.value)}
              />
              ₴
            </div>
          </div>
          <div className="myCurrencies-showRates-container">
            <div className="currency-currency-container">
              <PL title="United States" className="currency-showRates-flag" />
              <h3>PLN</h3>
            </div>
            <div className="myCurrencies-input-wrapper">
              <input
                className="myCurrencies-input"
                type="number"
                value={plnToUah}
                placeholder="100₴"
                onChange={(e) => setPlnToUah(e.target.value)}
              />
              ₴
            </div>
          </div>
          <div className="myCurrencies-showRates-container">
            <div className="currency-currency-container">
              <UA title="United States" className="currency-showRates-flag" />
              <h3>UAH</h3>
            </div>
            <div className="myCurrencies-input-wrapper">
              <input
                className="myCurrencies-input"
                type="number"
                value={uahToUah}
                placeholder="100₴"
                onChange={(e) => setUahToUah(e.target.value)}
              />
              ₴
            </div>
          </div>
          <button onClick={handleClick} className="myCurrencies-button">
            Зберегти
          </button>
        </div>
        <Collapse in={alertSuccessOpen}>
          <Alert
            severity="success"
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertSuccessOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Курс валют успішно змінено!
          </Alert>
        </Collapse>
        <Collapse in={alertErrorOpen}>
          <Alert
            severity="error"
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertErrorOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Сталась помилка. Курс валют не змінено.
          </Alert>
        </Collapse>
      </div>
    </div>
  );
}

export default MyCurrencies;
