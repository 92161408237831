import React, { useState } from "react";
import axios from "axios";
import Select from "react-select";
import { US } from "country-flag-icons/react/3x2";
import { EU } from "country-flag-icons/react/3x2";
// const YOUR_API_KEY = 'a5ec84bf6983b79d0a497fd920bbeb55c90be056';
const YOUR_API_KEY = process.env.REACT_APP_EXCHANGE_API_KEY;

const CURRENCY_CODE = {
  USD: "USD",
  EUR: "EUR",
  UAH: "UAH",
};
function Currencies() {
	const [usdToUah, setUsdToUah] = useState(41.0);
	const [eurToUah, setEurToUah] = useState(45.0);
	const [currency, setCurrency] = useState('USD');
	const [amount, setAmount] = useState();

  const handleChange = (event) => {
    setCurrency(event.value);
  };

  const handleClick = async() => {
    try {
      await axios
      .get(`https://api.getgeoapi.com/v2/currency/convert?api_key=${YOUR_API_KEY}&from=${currency}
            &to=${CURRENCY_CODE.UAH}
            &amount=${amount}
            &format=json`)
        .then((res) => {
          console.log(res);
          setAmount(res.data.rates.UAH.rate_for_amount);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      color: state.isSelected ? "#ff7a22" : "black",
      padding: 20,
    }),
    control: () => ({
      color: "transparent",
      textShadow: "0 0 0 black",
      width: 100,
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
    }),
    input: () => ({
      display: "none",
    }),
  };

  const options = [
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
  ];

  Promise.all([
    axios.get(`https://api.getgeoapi.com/v2/currency/convert?api_key=${YOUR_API_KEY}&from=${CURRENCY_CODE.USD}
        &to=${CURRENCY_CODE.UAH}
        &amount=1
        &format=json`),
    axios.get(`https://api.getgeoapi.com/v2/currency/convert?api_key=${YOUR_API_KEY}&from=${CURRENCY_CODE.EUR}
        &to=${CURRENCY_CODE.UAH}
        &amount=1
        &format=json`),
  ]).then((values) => {
    setUsdToUah(values[0].data.rates.UAH.rate),
      setEurToUah(values[1].data.rates.UAH.rate);
  });

  return (
    <div className="currency-wrapper">
      <div className="currency-wrapper2">
        <h3 className="currency-title">Курс валют</h3>
        <div className="currency-containers-wrapper">
          <div className="currency-showRates-container">
            <div className="currency-currency-container">
              <US title="United States" className="currency-showRates-flag" />
              <h3>USD</h3>
            </div>
            <h3>{usdToUah}₴</h3>
          </div>
          <div className="currency-showRates-container">
            <div className="currency-currency-container">
              <EU title="United States" className="currency-showRates-flag" />
              <h3>EUR</h3>
            </div>
            <h3>{eurToUah}₴</h3>
          </div>
        </div>
      </div>
      <div className="currency-wrapper2">
        <h3 className="currency-title">Конвертор</h3>
        <div className="currency-containers-wrapper">
          <div className="currency-convert-container">
            <Select
              className="currency-convert-select"
              options={options}
              value={options.filter(function (option) {
                return option.value === currency;
              })}
              styles={customStyles}
              onChange={handleChange}
            />
            <input
              className="currency-convert-input"
              type="number"
              value={amount}
              placeholder="100₴"
              onChange={(e) =>setAmount( e.target.value)}
            />
          </div>
            <button onClick={handleClick} className="currency-convert-button">Конвертувати</button>
        </div>
      </div>
    </div>
  );
}

export default Currencies;
