import * as React from "react";
import { Edit, SimpleForm, TextInput, SelectInput } from "react-admin";
import MyFileField from "../fields/MyFileField";

export const CallOrdersEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="Телефон" source="phone" />
      <MyFileField label="Файл" source="file" />
      <TextInput label="Дата та час" disabled source="orderDateTime" />
      <SelectInput
        label="Статус"
        source="state"
        choices={[
          { id: "Оброблено✅", name: "Оброблено" },
          { id: "Не оброблено❌", name: "Не оброблено" },
        ]}
      ></SelectInput>
    </SimpleForm>
  </Edit>
);
