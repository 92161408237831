import React from "react";
import { useGetIdentity } from 'react-admin';
function DashboardHello() {
	const { identity, isLoading: identityLoading } = useGetIdentity();
	const name = process.env.REACT_APP_ADMIN_USER_NAME;
	function TimeText() {
		const Digital = new Date();
		const hours = Digital.getHours();
		if (hours >= 5 && hours <= 22) {
			return <h2>Доброго дня, &nbsp;{name}</h2>;
		}
		return <h2>Доброї ночі, &nbsp;{name}</h2>;
	}
	return (
		<div className='dashboardHello-wrapper'>
			<div>
				<TimeText />
			</div>
		</div>
	);
}

export default DashboardHello;